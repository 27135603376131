<template>
  <div>
    <page-title :heading="$t('accounting.lang_nav_collectivebill')" :subheading="$t('erp.lang_nav_faktura')" :icon=icon show-previous-button></page-title>

    <div class="app-main__inner">
      <CollectiveBillDay></CollectiveBillDay>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import CollectiveBillDay from "@/components/billing/collectivebill/CollectiveBillDay";

export default {
  components: {CollectiveBillDay, PageTitle},

  data: () => ({
    icon: 'pe-7s-folder icon-gradient bg-tempting-azure',
  })
}
</script>