<template>

  <v-container fluid>
    <v-card elevation="6" outlined>
      <v-card-text class="ma-0 pa-0">
        <v-card-title>{{ $route.params.day }}</v-card-title>
        <v-card-subtitle>{{ $t('generic.lang_customer') }} {{ $route.params.customer_id }}</v-card-subtitle>

        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.BILLING.COLLECTIVEBILLSDAYCUSTOMER"
            :datatable-headers="datatableHeaders"
            :excel-columns="excelColumns"
            :data="additionalGetParams"
        >
          <template v-slot:item.total="{item}">
            {{ item.total|currency }}
          </template>
        </Datatable>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";

export default {
  name: "CollectiveBillDay",

  components: {Datatable},

  data() {
    return {
      ENDPOINTS,
      additionalGetParams: {
        customerId: this.$route.params.customer_id,
        startDate: this.$route.params.startDate,
        endDate: this.$route.params.endDate
      },
      datatableHeaders: [
        {
          text: this.$t('accounting.lang_deliveryNoteNo'),
          value: "id"
        },
        {hide: true},
        {hide: true},
        {
          text: this.$t('generic.lang_date'),
          value: 'date'
        },
        {
          text: this.$t('generic.lang_total'),
          value: 'total'
        },
      ],
      excelColumns: [
        {
          label: this.$t("erp.lang_id"),
          field: "id",
        }
      ]
    }
  }
}
</script>
